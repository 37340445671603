import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import BannerSlider from "../../Containers/Slider/BannerSlider";
import WhoWeAreSwction from "../../Containers/HomePage/WhoWeAre";
import OurAcgievements from "../../Containers/HomePage/OurAchievements";
import LatestNewsSection from "../../Containers/HomePage/LatestNews";
import SuccessStoriesSection from "../../Containers/HomePage/SuccessStories";



const HomePage = (props) => {
  const location = useLocation();
  const [isEn] = useState(location.pathname.indexOf("/en") != -1);

  return (
    <React.Fragment>
      <Helmet>
        {isEn ? (
          <title>SAG &nbsp; | &nbsp; Home</title>
        ) : (
          <title>
            اللجنة المصرية السعودية المشتركة &nbsp; | &nbsp; الرئيسية
          </title>
        )}
      </Helmet>
      <BannerSlider /> <WhoWeAreSwction /> <OurAcgievements />
      <LatestNewsSection /> <SuccessStoriesSection />
    </React.Fragment>
  );
};

export default HomePage;
