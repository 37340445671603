import React from 'react';

const Title = props => {
    return (
        <h2
        className={`${props.classN} section_content_title mb-0 wow fadeInDown`}
        data-wow-duration="1s"
        data-wow-delay=".2s"
        style={props.style}
      >
       {props.children}
      </h2>
    );
}

export default Title