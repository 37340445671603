import React from "react";

import Classes from "./style.module.scss";

const Spinner = (props) => {
  return (
    <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
      <div className={Classes.Loader}>Loading...</div>
    </div>
  );
};

export default Spinner;
