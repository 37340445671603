import React from 'react';
import navLogo from '../../../Assets/global/logo.png';

import classes from './logo.module.css'

const logo = props => {
    return (
        <img className={classes.Logo} alt="SAG_Logo" src={navLogo} /> 
    );
}

export default logo;