import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import axios from "../../../axios-base";
import NewsCard from "../../AchieveCards";
import MainCard from "../../AchieveCards/MainCard";
import Title from "../../../Components/UI/Title";

import "./style.scss";

const OurAcgievements = (props) => {
  const location = useLocation();
  const [isEn] = useState(location.pathname.indexOf("/en") != -1);

  const [achievementsState, setAchievementsState] = useState(null);
  const [mainAchievements, setMainAchievements] = useState(null);

  useEffect(() => {
    const url = "/achievements";
    const url2 = "/mainachiev";
    axios
      .get(url)
      .then((res) => {
        setAchievementsState(res.data.achievements);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(url2)
      .then((res) => {
        console.log(res.data.main_achievement);
        setMainAchievements(res.data.main_achievement[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const baseUrl ="http://egyptsagfutureofjobs.com/SAG/";

  let achievementsList = null;

  if (achievementsState) {
    achievementsList = achievementsState.map((achList) => {
      return (
        <Col lg={6} sm={6} key={achList.id} className="mb-4">
          <NewsCard
            // delay={`.${indexOf}s`}
            link={isEn ? 'en/intermediaries' : 'ar/intermediaries'}
            imageSrc={baseUrl + achList.image}
            imageAlt="SAG"
            // title={isEn ? achList.title_en : achList.title_ar}
            text={(isEn
              ? achList.description_en
              : achList.description_ar
            ).substr(0,80) + '...'}
          />
        </Col>
      );
    });
  }

  let mainAchievementsCard = null;

  if (mainAchievements) {
    mainAchievementsCard = (
      <MainCard
        delay=".4s"
        // link={mainAchievements.link}
        link={isEn ? 'en/intermediaries' : 'ar/intermediaries'}
        imageSrc={baseUrl + mainAchievements.image}
        imageAlt="SAG"
        // title={isEn ? mainAchievements.title_en : mainAchievements.title_ar}
        text={(isEn
          ? mainAchievements.description_en
          : mainAchievements.description_ar
        ) + '...'}
      />
    );
  }

  return (
    <section className="section our_achievements">
      <Container>
        <div className="section_content mb-5">
          <Title>{isEn ? 'Our achievements' : 'إنجازاتنا'}</Title>
        </div>
        <Row>
          <Col xl={5} lg={5} className="mb-4">
            {mainAchievementsCard}
          </Col>
          <Col xl={7} lg={7}>
            <Row>{achievementsList}</Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OurAcgievements;
