import React, { useEffect, Suspense } from "react";
import { Route, Redirect, Switch, useLocation, useHistory } from "react-router-dom";
import HeaderNavbar from "./Components/Navbar";
import FooterSection from "./Components/Footer/Footer";
import HomePage from "./Components/HomePage";
import Spinner from "./Components/UI/Spinner";
import { WOW } from "wowjs";

import ReactGA from "react-ga";

// import useGoogleAnalytics from "./useGoogleAnalytics";

// Import Styles Here _____ //
import "./App.scss";

const WhoWeArePage = React.lazy(() => {
  return import("./Components/WhoWeArePage");
});
const ContactUs = React.lazy(() => {
  return import("./Components/ContactUsPage");
});
const LatestNewsPage = React.lazy(() => {
  return import("./Components/LatestNewsPage");
});
const NewsDetails = React.lazy(() => {
  return import("./Components/NewsDetails");
});
const SuccessStoriesPage = React.lazy(() => {
  return import("./Components/SuccessStoriesPage");
});
const IntermediariesPage = React.lazy(() => {
  return import("./Components/IntermediariesPage");
});
const OurAchievementsPage = React.lazy(() => {
  return import("./Components/OurAchievementsPage");
});
const NotFound = React.lazy(() => {
  return import("./Components/NotFound");
});
const CooperateWithUs = React.lazy(() => {
  return import("./Components/CooperateWithUsPage");
});
const SuccessStoriesDetailsPage = React.lazy(() => {
  return import("./Components/SuccessStoriesDetailsPage");
});
const ApplyForm = React.lazy(() => {
  return import("./Components/FullApply");
});
const FirstApplyForm = React.lazy(() => {
  return import("./Components/Apply");
});
const GalleryPage = React.lazy(() => {
  return import("./Components/GalleryPage");
});
const FastLinksPage = React.lazy(() => {
  return import("./Components/FastLinksPage");
});
const LinksDetails = React.lazy(() => {
  return import("./Components/LinksDetailsPage");
});

const App = (props) => {
  const location = useLocation();

  // useGoogleAnalytics();
  const history = useHistory();
  ReactGA.initialize("G-66D2XZNDPH"); //G-66D2XZNDPH
  history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search  );
    ReactGA.send({ page: location.pathname });
  });
  ReactGA.set({ page: location.pathname });
  ReactGA.send({ page: location.pathname });
  ReactGA.pageview(location.pathname + location.search);
  ReactGA.event({
    category: 'User',
    action: 'Created an Account'
  });
  ReactGA.exception({
    description: 'An error ocurred',
    fatal: true
  });

  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 5,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  return (
    <React.Fragment>
      <HeaderNavbar />
      <main>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route exact path="/" component={HomePage}>
              <Redirect to="/ar" />
            </Route>
            {/* Arabic Pages */}
            <Route exact path="/ar" component={HomePage}></Route>
            <Route
              path="/ar/who-we-are"
              render={(props) => <WhoWeArePage {...props} />}
            ></Route>
            <Route
              path="/ar/contact-us"
              render={(props) => <ContactUs {...props} />}
            ></Route>
            <Route
              path="/ar/latest-news"
              render={(props) => <LatestNewsPage {...props} />}
            ></Route>
            <Route
              path="/ar/news-details/:id"
              render={(props) => <NewsDetails {...props} />}
            ></Route>
            <Route
              path="/ar/success-stories"
              render={(props) => <SuccessStoriesPage {...props} />}
            ></Route>
            <Route
              path="/ar/intermediaries"
              render={(props) => <IntermediariesPage {...props} />}
            ></Route>
            <Route
              path="/ar/our-achievements"
              render={(props) => <OurAchievementsPage {...props} />}
            ></Route>
            <Route
              path="/ar/financing-opportunities"
              render={(props) => <CooperateWithUs {...props} />}
            ></Route>
            <Route
              path="/ar/success-stories-details/:id"
              render={(props) => <SuccessStoriesDetailsPage {...props} />}
            ></Route>
            <Route
              path="/ar/first-application-form"
              render={(props) => <FirstApplyForm {...props} />}
            >
              {/* <Redirect to="/en/application-form" /> */}
            </Route>
            <Route
              path="/ar/application-form"
              render={(props) => <ApplyForm {...props} />}
            >
              {/* <Redirect to="/en/application-form" /> */}
            </Route>
            <Route
              path="/ar/gallery"
              render={(props) => <GalleryPage {...props} />}
            />
            <Route
              path="/ar/useful-links"
              render={(props) => <FastLinksPage {...props} />}
            />
            <Route
              path="/ar/links-details/:id"
              render={(props) => <LinksDetails {...props} />}
            ></Route>
            {/* English Pages */}
            {/* <Route exact path="/" component={HomePage}>
              <Redirect to="/en" />
            </Route> */}
            <Route exact path="/en" component={HomePage} />
            <Route
              path="/en/who-we-are"
              render={(props) => <WhoWeArePage {...props} />}
            ></Route>
            <Route
              path="/en/contact-us"
              render={(props) => <ContactUs {...props} />}
            ></Route>
            <Route
              path="/en/latest-news"
              render={(props) => <LatestNewsPage {...props} />}
            ></Route>
            <Route
              path="/en/news-details/:id"
              render={(props) => <NewsDetails {...props} />}
            ></Route>
            <Route
              path="/en/success-stories"
              render={(props) => <SuccessStoriesPage {...props} />}
            ></Route>
            <Route
              path="/en/intermediaries"
              render={(props) => <IntermediariesPage {...props} />}
            ></Route>
            <Route
              path="/en/our-achievements"
              render={(props) => <OurAchievementsPage {...props} />}
            ></Route>
            <Route
              path="/en/financing-opportunities"
              render={(props) => <CooperateWithUs {...props} />}
            ></Route>
            <Route
              path="/en/success-stories-details/:id"
              render={(props) => <SuccessStoriesDetailsPage {...props} />}
            ></Route>
            <Route
              path="/en/first-application-form"
              render={(props) => <FirstApplyForm {...props} />}
            ></Route>
             <Route
              path="/en/application-form"
              render={(props) => <ApplyForm {...props} />}
            >
            </Route>
            <Route
              path="/en/gallery"
              render={(props) => <GalleryPage {...props} />}
            />
            <Route
              path="/en/useful-links"
              render={(props) => <FastLinksPage {...props} />}
            />
            <Route
              path="/en/links-details/:id"
              render={(props) => <LinksDetails {...props} />}
            ></Route>
            {/* Page Not Found */}
            <Route
              path="/not-found"
              render={(props) => <NotFound {...props} />}
            ></Route>
            <Redirect to="/not-found"></Redirect>
            {/* Page Not Found */}
          </Switch>
        </Suspense>
      </main>
      <FooterSection />
    </React.Fragment>
  );
};

export default App;
