import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import EnglishSlider from './EnglishSlider';
import ArabicSlider from './ArabicSlider';

import "./slider.scss";

const BannerSlider = (props) => {
  const location = useLocation();
  const [isEn] = useState(location.pathname.indexOf("/en") != -1);

  return <section className="banner">
    { isEn ? <EnglishSlider /> : <ArabicSlider /> }
  </section>;
};

export default BannerSlider;
