import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import image from "../../../Assets/HomePage/who.jpg";
import Button from "../../../Components/UI/Button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Title from "../../../Components/UI/Title";
import axios from "../../../axios-base";

import "./style.scss";

const WhoWeAreSection = (props) => {
  const location = useLocation();
  const [isEn] = useState(location.pathname.indexOf("/en") != -1);

  const [section] = useState({
    title: "من نحن",
    title_en: "Who we are",
    link: "/who-we-are",
    linkText: "اقرأ المزيد",
    linkText_en: "Read more",
    imgSrc: image,
    imgAlt: "WhoWeAre",
  });
  const [visionMessage, setVisionMessage] = useState(null);

  useEffect(() => {
    const url =
      "/getsnippet/about-us-home";
    axios
      .get(url)
      .then((res) => {
        setVisionMessage(res.data.snippet[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const baseUrl ="http://egyptsagfutureofjobs.com/SAG/";

  let visionMessageL = null;

  if (visionMessage) {
    visionMessageL = (
      <React.Fragment>
        <Col lg={6}>
          <div className={`section_content`}>
            <h3
              className="section_content_subtitle wow  fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".5s"
            >
              {isEn ? visionMessage.title_two_en : visionMessage.title_two_ar}
            </h3>
            <p
              className="section_content_text wow fadeInUp "
              data-wow-duration="1s"
              data-wow-delay=".8s"
            >
              {isEn
                ? visionMessage.description_two_en
                : visionMessage.description_two_ar}
            </p>
          </div>
        </Col>
        <Col lg={6}>
          <div className={`section_content message`}>
            <h3
              className="section_content_subtitle wow fadeInUp "
              data-wow-duration="1s"
              data-wow-delay=".5s"
            >
              {isEn ? visionMessage.title_one_en : visionMessage.title_one_ar}
            </h3>
            <p
              className="section_content_text wow fadeInUp "
              data-wow-duration="1s"
              data-wow-delay=".8s"
            >
              {isEn
                ? visionMessage.description_one_en
                : visionMessage.description_one_ar}
            </p>
          </div>
        </Col>
      </React.Fragment>
    );
  }

  let secImage = null;
  if (visionMessage) {
    secImage = (
      <LazyLoadImage
        className="img-fluid cover_img"
        alt={section.imgAlt}
        src={baseUrl + visionMessage.image}
      />
    );
  }

  return (
    <section className="section who_we_are">
      <Container>
        <Row>
          <Col lg={6} className="mb-4 mb-lg-0">
            <div className="section_content mb-4">
              <Title>{isEn ? section.title_en : section.title}</Title>
            </div>
            <Row>{visionMessageL}</Row>
            <div className="section_link mt-1">
              <Button toLink={location.pathname + section.link} delay="1s">
                {isEn ? section.linkText_en : section.linkText}
              </Button>
            </div>
          </Col>
          <Col lg={6}>
            <div
              className="section_img wow fadeInUp h-100"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              {secImage}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhoWeAreSection;
