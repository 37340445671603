import React, { useState, useEffect, useCallback } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import axios from "../../axios-base";
import Logo from "../Assets/logo/logo";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from "../../Containers/ScrollToTop";

import "./Footer.scss";

const FooterSection = (props) => {
  const location = useLocation();
  const [isEn] = useState(location.pathname.indexOf("/en") != -1);
  const [footerState, setFooterState] = useState(null);
  const [linkTarget] = useState('_blank');
  const baseUrl ="http://egyptsagfutureofjobs.com/SAG/";
  const footerAxios = useCallback(() => {
    const url = "/settings";
    axios
      .get(url)
      .then((res) => {
        setFooterState(res.data.settings);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    footerAxios();
  }, [footerAxios]);

  const [whoAreU] = useState([
    {
      id: "who_1",
      text: "الرؤية",
      text_en: 'Vision',
      link: "/ar/who-we-are",
      link_en: '/en/who-we-are'
    },
    {
      id: "who_2",
      text: "الرسالة",
      text_en: 'Message',
      link: "/ar/who-we-are",
      link_en: "/en/who-we-are",
    },
  ]);
  const [importantLinks] = useState([
    {
      id: "important_1",
      text: "آخر الأخبار",
      text_en: 'Latest news',
      link: "/ar/latest-news",
      link_en: '/en/latest-news',
    },
    // {
    //   id: "important_2",
    //   text: "من نحن",
    //   text_en: 'Who we are',
    //   link: "/ar/who-we-are",
    //   link_en: '/en/who-we-are',
    // },
    {
      id: "important_3",
      text: "إنجازاتنا",
      text_en: 'Our achievements',
      link: "/ar/our-achievements",
      link_en: '/en/our-achievements',
    },
    {
      id: "important_4",
      text: "فرص التمويل",
      text_en: 'Financing opportunities',
      link: "/ar/financing-opportunities",
      link_en: '/en/financing-opportunities',
    },
    {
      id: "important_5",
      text: "قصص النجاح",
      text_en: 'Success stories',
      link: "/ar/success-stories",
      link_en: '/en/success-stories',
    },
  ]);


  const whoAreUList = whoAreU.map((wru) => {
    return (
      <li key={wru.id} className="footer_links_list_item">
        <Link className="footer_links_list_item_link" to={isEn ? wru.link_en : wru.link}>
          <span>{isEn ? wru.text_en : wru.text}</span>
        </Link>
      </li>
    );
  });

  const importantList = importantLinks.map((imrLinks) => {
    return (
      <li key={imrLinks.id} className="footer_links_list_item">
        <Link className="footer_links_list_item_link" to={isEn ? imrLinks.link_en : imrLinks.link}>
          <span>{isEn ? imrLinks.text_en : imrLinks.text}</span>
        </Link>
      </li>
    );
  });

  let followUs = null;
  if(footerState) {
    followUs = <React.Fragment>
      <li className="footer_links_list_item">
        <a
          className="footer_links_list_item_link"
          href={footerState.facebook}
          target={linkTarget}
        >
         <FontAwesomeIcon icon={faFacebookF} />
          <span>{isEn ? 'Facebook' : 'فيسبوك'}</span>
        </a>
      </li>
      <li className="footer_links_list_item">
        <a
          className="footer_links_list_item_link"
          href={footerState.instagram}
          target={linkTarget}
        >
        <FontAwesomeIcon icon={faInstagram} />
          <span>{isEn ? 'Instagram' : 'انستجرام'}</span>
        </a>
      </li>
      <li className="footer_links_list_item">
        <a
          className="footer_links_list_item_link"
          href={footerState.twitter}
          target={linkTarget}
        >
        <FontAwesomeIcon icon={faTwitter} />
          <span>{isEn ? 'Twitter' : 'تويتر'}</span>
        </a>
      </li>
    </React.Fragment>
  }

  let footerTitleText = null;
  if (footerState) {
    footerTitleText = (
      <React.Fragment>
        <h5 className="footer_subtitle mt-2">
          {isEn ? footerState.footer_title_en : footerState.footer_title_ar}
        </h5>
        <p className="footer_text">
          {isEn ? footerState.footer_text_en : footerState.footer_text_ar}
        </p>
      </React.Fragment>
    );
  }

  let emailusList = null;
  if (footerState) {
    emailusList = (
      <React.Fragment>
        <li className="footer_links_list_item">
          <a
            className="footer_links_list_item_link"
            href="https://www.google.com/maps/place/8+Adly,+Al+Fawalah,+Abdeen,+Cairo+Governorate/@30.0509267,31.2434129,17z/data=!3m1!4b1!4m5!3m4!1s0x145840bdfd2d163b:0xe820c5f0ed255ee2!8m2!3d30.0509267!4d31.2456016"
            target={linkTarget}
          >
            <FontAwesomeIcon icon={faEnvelope} size="lg" />
            <span>
              {isEn ? footerState.address_en : footerState.address_ar}
            </span>
          </a>
        </li>
        <li className="footer_links_list_item">
          <a
            className="footer_links_list_item_link"
            href={`mailto:${footerState.email}`}
            target={linkTarget}
          >
            <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
            <span>
              {footerState.email}
            </span>
          </a>
        </li>
        <li className="footer_links_list_item">
          <a
            className="footer_links_list_item_link"
            href={`tel:${footerState.phone}`}
            target={linkTarget}
          >
            <FontAwesomeIcon icon={faPhoneAlt} size="lg" />
            <span>
              {footerState.phone}
            </span>
          </a>
        </li>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <footer>
        <div className="footer">
          <Container>
            <Row>
              <Col xl={4} sm={6} className="mb-4 mb-xl-0">
                <div className="footer_logo">
                  <Logo />
                  {footerTitleText}
                </div>
              </Col>
              <Col xl sm={6} className="mb-4 mb-xl-0">
                <div className="footer_links">
                  <h5 className="footer_links_title">
                    {isEn ? "Contact us" : "اتصل بنا"}
                  </h5>
                  <ul className="list-unstyled footer_links_list">
                    {emailusList}
                  </ul>
                </div>
              </Col>
              <Col xl xs={4}>
                <div className="footer_links">
                  <h5 className="footer_links_title">{isEn ? 'Who we are' : 'من نحن'}</h5>
                  <ul className="list-unstyled footer_links_list">
                    {whoAreUList}
                  </ul>
                </div>
              </Col>
              <Col xl xs={4}>
                <div className="footer_links">
                  {/* <h5 className="footer_links_title">{isEn ? 'Useful Links' : 'روابط مفيدة'}</h5> */}
                  <ul className="list-unstyled footer_links_list">
                    {importantList}
                  </ul>
                </div>
              </Col>
              {/* <Col xl xs={4}>
                <div className="footer_links">
                  <h5 className="footer_links_title">{isEn ? 'Follow us' : 'تابعنا'}</h5>
                  <ul className="list-unstyled footer_links_list">
                    {followUs}
                  </ul>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
        <div className="copyright">
          <Container className="text-center">
            <p>
              Designed & Developed by{" "}
              <a href="https://icon-creations.com/">Icon Creations</a>
            </p>
          </Container>
        </div>
      </footer>
      <ScrollToTop />
    </React.Fragment>
  );
};

export default withRouter(FooterSection);
