import React, { useState, useEffect, useCallback } from "react";
import axios from "../../../axios-base";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Card from "../../Card";
import Title from "../../../Components/UI/Title";

import Classes from "./style.module.scss";

const SuccessStoriesSection = (props) => {
  const location = useLocation();
  const [isEn] = useState(location.pathname.indexOf("/en") != -1);
  const [storiesState, setStoriesState] = useState(null);

  const storiesAxios = useCallback(() => {
    const url = "/homestories";
    axios
      .get(url)
      .then((res) => {
        setStoriesState(res.data.success_stories);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    storiesAxios();
  }, [storiesAxios]);
  const baseUrl ="http://egyptsagfutureofjobs.com/SAG/";

  let storiesStateList = null;

  if (storiesState) {
    storiesStateList = storiesState.map((sList) => {
      return (
        <Col lg={4} sm={6} key={sList.id} className="mb-4">
          <Card
            link={isEn ? `/en/success-stories-details/${sList.id}` : `/ar/success-stories-details/${sList.id}`}
            imageSrc={baseUrl + sList.image}
            text={(
              isEn ? sList.short_description_en : sList.short_description_ar).split(' ', 15).join(' ') + ' ...'
            }
          />
        </Col>
      );
    });
  }
  return (
    <section className={`section ${Classes.SuccessStories} ${props.sucClass}`}>
      <Container>
        <div className={`section_content mb-0 whtColor ${props.sucTitleClass}`}>
          <Title> {isEn ? "Success stories" : "قصص النجاح"}</Title>
        </div>
        {/* <div className="text-left">
          <div className={!isEn ? "text-left" : "text-right"}>
            <Link
              to={isEn ? "en/success-stories" : "ar/success-stories"}
              className="section_link wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".3s"
            >
              {isEn ? "View all" : "عرض الكل"}
              <FontAwesomeIcon icon={!isEn ? faChevronLeft : faChevronRight} />
            </Link>
          </div>
        </div> */}
        <Row className="mt-5">{storiesStateList}</Row>
      </Container>
    </section>
  );
};

export default SuccessStoriesSection;
