import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";


import Classes from "./style.module.scss";

const Card = (props) => {
  const location = useLocation();
  const [isEn] = useState(location.pathname.indexOf("/en") != -1);
  return (
    <Link to={props.link} className="d-block h-100 overflow-hidden">
      <div className={`${Classes.Card} wow fadeInUp`} data-wow-duration="1s" data-wow-delay={props.delay}>
        <div className={`${Classes.Card_header} ${props.headerClassName}`}>
          <LazyLoadImage
            src={props.imageSrc}
            alt={props.imageAlt}
            width="100%"
            className="img-fluid"
          />
        </div>
        <div className={Classes.Card_body}>
          <div className={Classes.Card_body_content}>
            {/* <h5 className={Classes.Card_body_content_title}>{props.title}</h5> */}
            <p className={Classes.Card_body_content_text}>{props.text}</p>
            <button className={`${Classes.Card_body_content_link} specific_card_link`}>
            {isEn ? 'Read more' : 'اقرأ المزيد'}
              
              <FontAwesomeIcon icon={! isEn ? faChevronLeft : faChevronRight} />
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
