import React, { useState, useEffect } from "react";
import Button from "../../../Components/UI/Button";
import axios from "../../../axios-base";
// Import Wow React components

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar, 
  A11y,
  Autoplay,
} from "swiper";
import { Container, Row, Col } from "react-bootstrap";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import "./slider.scss";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
const ArabicSlider = (props) => {
  const [sliderState, setSliderState] = useState(null);

  useEffect(() => {
    const url = "/slider";
    axios
      .get(url)
      .then((res) => {
        setSliderState(res.data.sliders);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const baseUrl ="http://egyptsagfutureofjobs.com/SAG/";
  let sliderContent = null;

  if (sliderState) {
    sliderContent = (
      <Swiper
        className="bannerSlider"
        slidesPerView={1}
        pagination={{ clickable: true }}
        speed={1200}
        autoplay={{ delay: 10000 }}
        loop={true}
      >
        {sliderState.map((slideS) => {
          return (
            <SwiperSlide key={slideS.id} className="bannerSlider_slide">
              <Container>
                <Row className="align-items-center">
                  <Col xl={5} md={6} className="mb-4 mb-md-0 col-content">
                    <div className="bannerSlider_slide_content">
                      <h3 className="bannerSlider_slide_content_title animated">
                        {slideS.title_ar}
                      </h3>

                      <p className="bannerSlider_slide_content_text animated">
                        {slideS.description_ar}
                      </p>
                      <div className="bannerSlider_slide_content_link animated">
                        <Button toLink={`/ar/${slideS.link}`}>اقرأ المزيد</Button>
                      </div>
                    </div>
                  </Col>
                  <Col xl={7} md={6} className="col-img">
                    <div className="bannerSlider_slide_img animated">
                      <img src={baseUrl + slideS.image} className="img-fluid" alt="SAG" />
                    </div>
                  </Col>
                </Row>
              </Container>
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  }

  return sliderContent;
};

export default ArabicSlider;
