import React from "react";

import "./sideButton.scss";
const SideButton = (props) => {
    


  return (
    <button className={props.openClass} onClick={props.click} >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

export default SideButton;
