import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

import Classes from "./style.module.scss";

const ScrollToTop = (props) => {
  const [minScroll] = useState(window.scrollY > 1000);
  const scrollHandler = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (
      window.addEventListener("scroll", () => {
        if (window.scrollY > 1000) {
          document.getElementById("dScrollToTop").classList.add("d-flex");
          document.getElementById("dScrollToTop").classList.remove("d-none");
        } else {
          document.getElementById("dScrollToTop").classList.add("d-none");
          document.getElementById("dScrollToTop").classList.remove("d-flex");
        }
      })
    );
  }, []);

  return (
    <div
      id="dScrollToTop"
      className={`${Classes.ScrollTop} ${minScroll ? "d-flex" : "d-none"}`}
      onClick={scrollHandler}
    >
      <FontAwesomeIcon icon={faChevronUp} size="lg" />
    </div>
  );
};

export default ScrollToTop;
