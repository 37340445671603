import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Classes from "./style.module.scss";

const NewsCard = (props) => {
  const location = useLocation();
  const [isEn] = useState(location.pathname.indexOf("/en") != -1);
  return (
    <Link to={props.link} className="d-block h-100">
      <div
        className={`${Classes.Card} wow fadeInUp animated`}
        data-wow-duration="1s"
        data-wow-delay={props.delay}
      >
        <div className={Classes.Card_header}>
          <LazyLoadImage
            src={props.imageSrc}
            alt={props.imageAlt}
            className="img-fluid"
          />
        </div>
        <div className={Classes.Card_body}>
          <div className={Classes.Card_body_content}>
            <h5 className={Classes.Card_body_content_title}>{props.title}</h5>
            <p className={Classes.Card_body_content_text}>{props.text}</p>
            <button className="button">
              {isEn ? "Read more" : "اقرأ المزيد"}
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default NewsCard;
