import React, { useState,useEffect, useCallback } from "react";
import axios from '../../../axios-base';
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import Card from "../../Card";
import Title from "../../../Components/UI/Title";

import Classes from "./style.module.scss";

const LatestNewsSection = (props) => {
  const location = useLocation();
  const [isEn] = useState(location.pathname.indexOf("/en") != -1);
  const [allNews, setAllNews] = useState(null);
  const baseUrl ="http://egyptsagfutureofjobs.com/SAG/";
  const allNewsAxxios = useCallback(() => {
    const url = "/randomnews";
    axios
      .get(url)
      .then((res) => {
        setAllNews(res.data.random_news);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    allNewsAxxios();
  }, [allNewsAxxios]);

  let allNewsList = null;
  if(allNews){
    allNewsList = allNews.map(allNewsL => {
      return (
        <Col sm={4} key={allNewsL.id} className="mb-4 mb-sm-0">
        <Card
          headerClassName="custNewsHeader"
          link={ isEn ? `/en/news-details/${allNewsL.id}` : `/ar/news-details/${allNewsL.id}`}
          imageSrc={baseUrl + allNewsL.image}
          text={(isEn ? allNewsL.short_description_en : allNewsL.short_description_ar).substr(
              0,
              50
            )+ ' ...'}
        />
      </Col>
      );
    });
  };

  return (
    <section className={`section ${Classes.LatestNews}`}>
      <Container>
        <div className="section_content mb-0">
          <Title>{isEn ? "Latest news" : "آخر الأخبار"}</Title>
        </div>
        <div className={!isEn ? "text-left" : "text-right"}>
          <Link
            to={`${location.pathname}/latest-news`}
            className="section_link wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".3s"
          >
            {isEn ? "View all" : "عرض الكل"}
            <FontAwesomeIcon icon={!isEn ? faChevronLeft : faChevronRight} />
          </Link>
        </div>
        <Row className="mt-5">
          <Col lg={12}>
            <Row>{allNewsList}</Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LatestNewsSection;
