import React, { useState, useCallback, useEffect } from "react";
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
// Bootstrap
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// Logo
import Logo from "../Assets/logo/logo";
// SideToggle
import SideButton from "../UI/sideButtonToggle";
// Sidebar
import Sidebar from "../Sidebar";

import "./Navbar.scss";

const HeaderNavbar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [scrolled, setScrolled] = useState("");
  const [langTitle, setLangTitle] = useState("عربي");
  const [isEn, setIsEn] = useState(location.pathname.indexOf("/en") != -1);
  const [englishLink, setEnglishLink] = useState(
    location.pathname.replace("/ar", "/en")
  );
  const [arabicLink, setArabicLink] = useState(
    location.pathname.replace("/en", "/ar")
  );

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(
    (e) => {
      setOpen(!open);
    },
    [open]
  );

  const changeLangEn = useCallback(
    (e) => {
      e.preventDefault();
      setLangTitle("En");
      const pathEn = location.pathname.replace("/ar", "/en");
      history.push(pathEn);
      setIsEn(true);
      document.body.classList.add("en");
      window.location.reload();
    },
    [history, location]
  );
  const changeLangAr = useCallback(
    (e) => {
      e.preventDefault();
      setLangTitle("عربي");
      const pathAr = location.pathname.replace("/en", "/ar");
      history.push(pathAr);
      setIsEn(false);
      document.body.classList.remove("en");
      window.location.reload();
    },
    [history, location]
  );

  const addBodyClass = useCallback(() => {
    if (isEn) {
      document.body.classList.add("en");
      setLangTitle("En");
    } else {
      document.body.classList.remove("en");
      setLangTitle("عربي");
    }
  }, [isEn]);

  useEffect(() => {
    history.listen((location) => {
      setEnglishLink(location.pathname.replace("/ar", "/en"));
    });
    history.listen((location) => {
      setArabicLink(location.pathname.replace("/en", "/ar"));
    });

    if (isEn) {
      document.body.classList.add("en");
      setLangTitle("En");
    } else {
      document.body.classList.remove("en");
      setLangTitle("عربي");
    }

    window.addEventListener("scroll", handleScroll);
  }, [isEn, location, history]);

  const handleScroll = () => {
    if (window.scrollY > 1) {
      setScrolled("scrolled");
    } else {
      setScrolled("");
    }
  };

  
  const clickedHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <header className={scrolled}>
      <Navbar className="navbar" expand="lg">
        <div className="container">
          <Navbar.Brand as={Link} to={isEn ? '/en' : '/ar'} className="navbar_brrand py-0">
            <Logo />
          </Navbar.Brand>
          <Nav
            className={!isEn ? "mr-auto ml-sm-4 ml-2" : "ml-auto mr-sm-4 mr-2"}
          >
            <NavDropdown
              title={langTitle}
              id="basic-nav-dropdown"
              className="navbar_list_dropdown"
            >
              <NavDropdown.Item
                as={Link}
                to={arabicLink}
                onClick={changeLangAr}
                className="navbar_list_dropdown_item"
              >
                عربي
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={englishLink}
                onClick={changeLangEn}
                className="navbar_list_dropdown_item"
              >
                En
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <SideButton
            openClass={open ? "side_button open" : "side_button"}
            click={handleClick}
          />
          <Sidebar sideClass={open ? "sidebar opend" : "sidebar"} linkSideHandler={clickedHandler} />
        </div>
      </Navbar>
    </header>
  );
};

export default withRouter(HeaderNavbar);
