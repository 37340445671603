import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import "./Sidebar.scss";

const Sidebar = React.memo((props) => {
  const location = useLocation();
  const [isEn] = useState(location.pathname.indexOf('/en') != -1);
  const [sidebarList] = useState([
    {
      id: "1",
      title: !isEn  ? "الرئيسية" : "Home",
      to: !isEn  ? "/ar" : "/en",
    },
    {
      id: "2",
      title:!isEn  ? "من نحن" : "Who we are",
      to:!isEn  ? "/ar/who-we-are" : "/en/who-we-are",
    },
    {
      id: "3",
      title:!isEn  ? "آخر الأخبار" : "Latest news",
      to:!isEn  ? "/ar/latest-news" : "/en/latest-news",
    },
    {
      id: "4",
      title:!isEn  ? "إنجازاتنا" : "Our achievements",
      to:!isEn  ? "/ar/our-achievements" : "/en/our-achievements",
    },
    {
      id: "5",
      title:!isEn  ? "فرص التمويل" : "Financing opportunities",
      to:!isEn  ? "/ar/financing-opportunities" : "/en/financing-opportunities",
    },
    {
      id: "7",
      title: !isEn  ? "قصص النجاح" : "Success stories",
      to: !isEn  ? "/ar/success-stories" : "/en/success-stories",
    },
    {
      id: "9",
      title: !isEn  ? "المعرض " : "Gallery",
      to: !isEn  ? "/ar/gallery" : "/en/gallery",
    },
    {
      id: "10",
      title: !isEn  ? "روابط مفيدة" : "Useful links",
      to: !isEn  ? "/ar/useful-links" : "/en/useful-links",
    },
    {
      id: "8",
      title: !isEn  ? "اتصل بنا" : "Contact us",
      to: !isEn  ? "/ar/contact-us" : "/en/contact-us",
    }, 
  ]);


  const sidebarItems = sidebarList.map((sideList) => {
    return (
      <li key={sideList.id} className="sidebar_list_item sidebar_list_item">
        <NavLink className="sidebar_list_item_link" exact to={sideList.to} onClick={props.linkSideHandler}>
          {sideList.title}
        </NavLink>
      </li>
    );
  });

  return (
    <div className={props.sideClass}>
      <div className="container">
        <ul className="list-unstyled sidebar_list mb-3">{sidebarItems}</ul>
        <ul className="list-unstyled sidebar_listSocial mb-3">
          {/* {sidebarSocialItems} */}
        </ul>
      </div>
    </div>
  );
});

export default Sidebar;
